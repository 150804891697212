<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Borrowers
        </strong>
      </div>
      <b-table
        small
        :fields="fields"
        :items="clients"
        responsive="sm"
        selectable
        select-mode="single"
        @row-clicked="handleRowClick"
      >
        <template #cell(action)="data">
          <hwa-button
            :icon="data.item.active?'UserXIcon':'UserCheckIcon'"
            :icon-only="true"
            :variant="data.item.active?'flat-danger':'flat-success'"
            @onClick="updateClient(data.item)"
          />
        </template>
        <template #cell(roles)="data">
          <div
            v-for="(role,index) in data.item.roles"
            :key="index"
            class="px-1"
          >
            <b-badge variant="light-success">
              {{ role.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(can_login)="data">
          <b-badge :variant="data.item.can_login?'light-success':'light-danger'">
            {{ data.item.can_login?"Enabled":"Disabled" }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="light-success">
            {{ data.item.status==='active'?"Active":"Inactive" }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="clientForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        :title="`Borrower`"
        :show="clientModalOpened"
        size="md"
        hide-footer
        :is_data_change="is_client_change"
        @onClose="closeClientModal()"
      >

        <div class="row">
          <div class="col-md-6"> <hwa-input
            v-model="client.first_name"
            name="name"
            label="First Name"
            placeholder="Enter first name"
            rules="required"
          /></div>
          <div class="col-md-6">   <hwa-input
            v-model="client.last_name"
            name="name"
            label="Last Name"
            placeholder="Enter last name"
            rules="required"
          />
          </div>
          <div class="col-md-6">
            <hwa-input
              v-model="client.email"
              name="name"
              label="Email"
              placeholder="Enter email"
              rules="required"
              type="email"
            />

          </div>
          <div class="col-md-6">
            <hwa-input
              v-model="client.phone"
              name="name"
              label="Phone Number"
              placeholder="Enter phone number"
            />
          </div>
        </div>

        <div class="row mt-3">
          <!-- <div class="col-md-3">
            <b-form-group label="Status">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                :checked="agent.status === 'active'? true : false"
                @change="setActiveUser(agent)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div> -->
          <div class="col-md-6">
            <b-form-group label="Login Access">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                :checked="Boolean(client.can_login) === true ? true : false"
                @change="setLoginAccess(client)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="UserCheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="UserXIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex mt-3 justify-content-between" v-if="$can('update-borrowers','hwa')">
          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            :class="is_client_change ? 'active-secondary' : ''"
            @onClick="closeClientModal()"
          />
          <hwa-button
            variant="success"
            :class="is_client_change === true ? 'active-success' : ''"
            :label="updateClientMode?'Save':'Save'"
            :disable="invalid || is_client_change === false ? true : false"
            :loading="clientLoading"
            loading-text="Loading..."
            @onClick="updateClient()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BBadge, BAlert, BFormGroup, BFormCheckbox, BFormRadioGroup, BRow, BFormSelect, BAvatar,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'

export default {
  components: {
    HwaModal,
    HwaInput,
    HwaButton,
    BTable,
    BCard,
    BBadge,
    ValidationObserver,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BRow,
    BFormSelect,
    BAvatar,
    BAlert,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{key:'full_name',label:"Full Name",sortable:true}, 'email', 'phone', {key:'can_login',label:"Login Access",sortable:true}, {key:'roles',label:"Role",sortable:true}],
      clients: [],
      client_name: '',
      client_id: null,
      clientLoading: false,
      updateClientMode: false,
      clientModalOpened: false,
      selectedClient: {},
      client: {
        first_name: '',
        last_name: '',
        email: '',
        status: 'active',
        phone: '',
        can_login: true,
      },
      roles: [],
      teams: [],
      is_client_change: false,
    }
  },
  computed: {

  },
  watch: {
    client: {
      handler() {
        this.is_client_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getClients()
  },
  methods: {
    openClientModal() {
      this.updateClientMode = false
      this.clientModalOpened = true
    },
    closeClientModal() {
      if (this.is_client_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.clientModalOpened = false
            this.is_client_change = false

            this.updateClient()
          } else if (result.dismiss === 'cancel') {
            this.clientModalOpened = false
            this.is_client_change = false
          }
        })
      } else {
        this.clientModalOpened = false
        this.is_client_change = false
      }
    },
    setActiveUser(data) {
      if (data.status === 'active') {
        this.agent.status = 'inactive'
      } else {
        this.agent.status = 'active'
      }
    },
    setLoginAccess(data) {
      if (Boolean(data.can_login) === true) {
        this.client.can_login = false
      } else {
        this.client.can_login = true
      }
    },
    updateClient() {
      this.clientLoading = true
      this.client.licensee_id = this.selected_licensee
      this.$http.put('/api/update-user-account', this.client)
        .then(res => {
          this.showSuccess('Updated succesfully')
          this.clientModalOpened = false
          this.is_client_change = false
          // this.closeClientModal()
          this.clientLoading = false
          this.clients = this.clients.map(c => {
            if (c.id === res.data.id) {
              c.full_name = res.data.full_name
              c.email = res.data.email
              c.can_login = res.data.can_login
              c.status = res.data.status
              c.phone = res.data.phone
              return c
            }
            return c
          })
        })
        .catch(err => {
          this.clientLoading = false
          this.showError('Failed to update clients')
        })
    },
    handleRowClick(row) {
      if (row) {
        this.client = { ...row }
        this.updateClientMode = true
        this.clientModalOpened = true

        const dom = this
        setTimeout(() => {
          dom.is_client_change = false
        }, 100)
      }
    },
    getClients() {
      this.$http.get('/api/clients')
        .then(res => {
          this.clients = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve clients')
        })
    },
  },
}
</script>
